/*============================================================================
  Classic | A theme by Shopify
  Built on Timber v2.0.0
==============================================================================*/
/*================ Variables, theme settings, and Sass mixins from Timber ================*/
/*================ Global | Sass Mixins ================*/
/*============================================================================
  Dependency-free breakpoint mixin
    - http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
==============================================================================*/
/*============================================================================
  Accent text
==============================================================================*/
/*================ The following are dependencies of csswizardry grid ================*/
/*================ Color variables ================*/
/*================ Typography variables ================*/
/*================ Vendor-specific styles ================*/
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #fffcfc;
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #555555;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after, .mfp-arrow .mfp-a {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before, .mfp-arrow .mfp-b {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }

  .mfp-img-mobile img.mfp-img {
    padding: 0; }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }
.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

/*================ Theme-specific partials ================*/
h1, .h1 {
  font-size: 1.88235em;
  line-height: 1.2; }

h2, .h2 {
  font-size: 1.64706em;
  line-height: 1.3; }

h3, .h3 {
  font-size: 1.29412em;
  line-height: 1.4; }

h4, .h4,
.tags {
  font-size: 0.94118em;
  line-height: 1.6; }

h4, .h4 {
  font-size: 0.94118em;
  font-weight: bold; }

h5, .h5 {
  font-size: 0.94118em;
  line-height: 1.6; }

h6, .h6 {
  font-size: 0.82353em;
  line-height: 1.7; }

/*================ Footer ================*/
.site-footer p,
.site-footer li,
.site-footer .rte,
.site-footer input {
  font-size: 0.85em; }

.main-content {
  margin-top: 15px; }
  .template-index .main-content {
    margin-top: 0; }

/*================ Module-specific styles ================*/
.header-bar {
  *zoom: 1;
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.82353em;
  font-weight: 400;
  background-color: #9e9b98;
  color: #f2f2f2;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center; }
  .header-bar:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (min-width: 769px) {
    .header-bar {
      text-align: right;
      padding-top: 8px;
      padding-bottom: 8px; } }
  .header-bar a,
  .header-bar button {
    color: #f2f2f2; }
    .header-bar a:hover, .header-bar a:active, .header-bar a:focus,
    .header-bar button:hover,
    .header-bar button:active,
    .header-bar button:focus {
      outline-color: #f2f2f2; }
  .header-bar .inline-list {
    margin-bottom: 0; }
    .header-bar .inline-list li {
      margin-bottom: 0; }

@media screen and (min-width: 769px) {
  .header-bar__left {
    text-align: left;
    width: 33.33%; }

  .header-bar__right {
    width: 66.66%; } }
.header-bar__module {
  margin-bottom: 15px; }
  .header-bar__right .header-bar__module:last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 769px) {
    .header-bar__module {
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      margin-bottom: 0; } }

.header-bar__module--list {
  list-style: none;
  margin: 0; }
  .header-bar__module--list li {
    display: inline-block;
    margin: 0; }
    .header-bar__module--list li + li {
      margin-left: 6px; }

.cart-page-link {
  display: inline-block; }

.header-bar__cart-icon {
  font-size: 1.4em;
  margin-right: 4px; }

.hidden-count {
  display: none; }

.header-bar__sep {
  display: none; }
  @media screen and (min-width: 769px) {
    .header-bar__sep {
      color: #f2f2f2;
      opacity: 0.4;
      display: inline-block;
      padding: 0 10px; } }

.header-bar__search {
  *zoom: 1;
  position: relative;
  background-color: #fff;
  border: 0 none;
  border-radius: 2px;
  min-width: 100px; }
  .header-bar__search:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (min-width: 769px) {
    .header-bar__search {
      max-width: 160px;
      margin-left: 20px; }
      .header-bar__search:first-of-type {
        margin-left: 0; } }
  @media screen and (max-width: 768px) {
    .header-bar__search {
      margin: 12px 30px; } }
  @media screen and (max-width: 480px) {
    .header-bar__search {
      margin: 12px 15px; } }
  .header-bar__search form,
  .header-bar__search input,
  .header-bar__search button {
    margin-bottom: 0; }

.header-bar__search-input[type="search"] {
  display: block;
  width: 60%;
  float: right;
  background: transparent;
  border-color: transparent;
  padding: 5px 0; }
  .header-bar__search-input[type="search"]:focus {
    background: transparent;
    border-color: transparent; }

.header-bar__search-submit {
  position: absolute;
  display: block;
  float: left;
  width: 40%;
  font-size: 16px;
  padding: 4px 0; }

.supports-fontface .header-bar__search-submit {
  width: 20%; }
.supports-fontface .header-bar__search-input[type="search"] {
  width: 100%;
  padding-left: 30px; }
@media screen and (max-width: 768px) {
  .supports-fontface .header-bar__search-form {
    position: relative; }
  .supports-fontface .header-bar__search-submit {
    width: 35px;
    position: absolute;
    top: 0;
    left: 0; }
  .supports-fontface .header-bar__search-input[type="search"] {
    width: 100%;
    padding-left: 35px; } }

.header-bar__search .btn,
.header-bar__search .btn:hover,
.header-bar__search .btn:focus {
  background: transparent;
  color: #555; }

/*================ Module | Grid Link ================*/
.grid-link__container {
  margin-bottom: -30px; }

.grid-link,
.grid-link--focus {
  position: relative;
  display: block;
  padding-bottom: 30px;
  line-height: 1.3; }
  .grid-link:hover .grid-link__image, .grid-link:active .grid-link__image,
  .grid-link--focus:hover .grid-link__image,
  .grid-link--focus:active .grid-link__image {
    opacity: 0.8; }

.grid-link--focus {
  padding: 20px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px; }
  .grid-link--focus:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cfcfcf;
    -webkit-transition: all 0.08s ease-in;
    -moz-transition: all 0.08s ease-in;
    -ms-transition: all 0.08s ease-in;
    -o-transition: all 0.08s ease-in;
    transition: all 0.08s ease-in; }
  .grid-link--focus:hover, .grid-link--focus:active {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.grid-link__image {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0 auto 10px;
  -webkit-transition: all 0.08s ease-in;
  -moz-transition: all 0.08s ease-in;
  -ms-transition: all 0.08s ease-in;
  -o-transition: all 0.08s ease-in;
  transition: all 0.08s ease-in; }
  .grid-link__image img {
    display: block;
    margin: 0 auto;
    max-width: 100%; }
    .sold-out .grid-link__image img {
      opacity: 0.4;
      filter: alpha(opacity=40); }
  .list-view__product .grid-link__image {
    min-width: 130px; }

.grid-link__image-centered {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  overflow: hidden; }

.grid-link__title,
.grid-link__meta {
  position: relative;
  margin-bottom: 5px; }

.grid-link__title {
  color: #555555;
  font-size: .9em;
  line-height: 1.4;
  font-weight: bold; }

.grid-link__vendor {
  font-size: .85em;
  font-weight: normal; }

.grid-link__meta {
  font-size: .75em;
  line-height: 1.5;
  color: #6e6e6e; }

.grid-link__sale_price {
  opacity: 0.7;
  filter: alpha(opacity=70); }

.list-view__product {
  border-bottom: 1px solid #069efc;
  margin-bottom: 10px;
  padding-bottom: 10px; }

.badge {
  display: table;
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #f45b4f;
  color: white;
  border-radius: 50%;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 0.70588em;
  line-height: 1.1;
  z-index: 10; }

.badge--sold-out {
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  background-color: #2b2929;
  color: white; }

.badge--sale {
  top: -12px;
  right: -12px; }

.badge__text {
  display: table-cell;
  vertical-align: middle;
  padding: 2px 8px 0; }

.badge__text--small {
  font-size: 8px;
  padding-top: 0; }

.mobile-nav-trigger,
.mobile-cart-page-link {
  font-weight: bold; }
  .mobile-nav-trigger .icon,
  .mobile-cart-page-link .icon {
    position: relative;
    top: -1px;
    vertical-align: middle;
    padding-right: 4px; }

.mobile-nav-trigger {
  display: block;
  float: left;
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0; }
  .mobile-nav-trigger .icon {
    font-size: 1.4em; }

.mobile-cart-page-link {
  display: block;
  float: right; }
  .mobile-cart-page-link .header-bar__cart-icon {
    font-size: 1.4em; }
  .mobile-cart-page-link .cart-count:before {
    display: inline;
    content: "("; }
  .mobile-cart-page-link .cart-count:after {
    display: inline;
    content: ")"; }

.mobile-nav {
  display: none;
  list-style: none;
  text-align: left;
  margin: 0; }
  .mobile-nav li {
    margin: 0; }

.mobile-nav__link {
  display: block;
  border-top: 1px solid #f2f2f2;
  border-color: rgba(242, 242, 242, 0.2);
  /*================ Can't always control anchor markup to add a class ================*/ }
  .mobile-nav__link > a {
    display: block;
    padding: 12px 15px;
    font-size: 0.88235em;
    font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", sans-serif;
    font-weight: 400;
    text-transform: uppercase; }
    @media screen and (min-width: 480px) {
      .mobile-nav__link > a {
        padding-left: 30px;
        padding-right: 30px; } }

.mobile-nav__sublist-expand,
.mobile-nav__sublist-contract {
  display: inline-block;
  font-size: 0.6em;
  vertical-align: middle;
  margin: -2px 0 0 4px; }

.mobile-nav__sublist-contract {
  display: none; }

.mobile-nav__sublist-trigger.is-active .mobile-nav__sublist-contract {
  display: inline-block; }
.mobile-nav__sublist-trigger.is-active .mobile-nav__sublist-expand {
  display: none; }

.mobile-nav__sublist {
  list-style: none;
  margin: 0;
  display: none;
  background-color: #fffcfc; }
  .mobile-nav__sublist .mobile-nav__sublist {
    margin-left: 15px; }
    .mobile-nav__sublist .mobile-nav__sublist .mobile-nav__sublist-link a {
      border-top: none; }

.mobile-nav__sublist-link a {
  display: block;
  padding: 12px 15px;
  color: #ff8b1d;
  font-size: 0.88235em;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", sans-serif;
  border-top: 1px solid #069efc; }
  @media screen and (min-width: 480px) {
    .mobile-nav__sublist-link a {
      padding-left: 30px;
      padding-right: 30px; } }
  .mobile-nav__sublist-link a:hover {
    opacity: 1;
    color: #f45b4f; }

/*================ Module | Promo images ================*/
.promo-images .grid__item {
  margin-bottom: 15px; }

/*================ Module | Product Lightbox ================*/
.mfp-bg {
  background-color: #fffcfc; }
  .mfp-bg.mfp-fade {
    -webkit-backface-visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .mfp-bg.mfp-fade.mfp-ready {
      opacity: 1;
      filter: alpha(opacity=100); }
    .mfp-bg.mfp-fade.mfp-removing {
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      opacity: 0;
      filter: alpha(opacity=0); }

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }
.mfp-fade.mfp-wrap.mfp-removing {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0; }
  .mfp-fade.mfp-wrap.mfp-removing button {
    opacity: 0; }

.mfp-counter {
  display: none; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: zoom-out; }
.mfp-figure:after {
  box-shadow: none; }

.mfp-img {
  background-color: #fffcfc; }

button.mfp-close {
  margin: 30px;
  font-size: 2.35294em;
  font-weight: 300px;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #555555; }

button.mfp-arrow {
  top: 0;
  height: 100%;
  width: 20%;
  margin: 0;
  opacity: 1;
  filter: alpha(opacity=100); }
  button.mfp-arrow:after, button.mfp-arrow .mfp-a {
    display: none; }
  button.mfp-arrow:before, button.mfp-arrow .mfp-b {
    display: none; }
  button.mfp-arrow:active {
    margin-top: 0; }

.mfp-chevron {
  position: absolute;
  pointer-events: none; }
  .mfp-chevron:before {
    content: '';
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 25px;
    width: 25px;
    border-style: solid;
    border-width: 4px 4px 0 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .mfp-chevron.mfp-chevron-right {
    right: 55px; }
    .mfp-chevron.mfp-chevron-right:before {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
  .mfp-chevron.mfp-chevron-left {
    left: 55px; }
    .mfp-chevron.mfp-chevron-left:before {
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg); }

.lt-ie9 .mfp-chevron:before,
.lt-ie9 .mfp-chevron:after {
  content: " ";
  position: absolute;
  display: block;
  border-width: 0;
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -25px;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent; }
.lt-ie9 .mfp-chevron:before {
  z-index: 5; }
.lt-ie9 .mfp-chevron:after {
  z-index: 2; }
.lt-ie9 .mfp-chevron-right:after {
  border-left: 25px solid #555555;
  left: 80%; }
.lt-ie9 .mfp-chevron-right:before {
  border-left: 25px solid white;
  left: 80%; }
.lt-ie9 .mfp-chevron-left:after {
  border-right: 25px solid #555555;
  right: 80%; }
.lt-ie9 .mfp-chevron-left:before {
  border-right: 25px solid white;
  right: 80%; }

/*============================================================================
  #FlexSlider
    - jQuery FlexSlider v2.2.0 | http://www.woothemes.com/flexslider/
    - Contributing author: Tyler Smith (@mbmufffin)
==============================================================================*/
.flexslider {
  margin: 0;
  padding: 0; }

.flexslider li {
  margin: 0;
  max-width: 100%; }

.flexslider .slides > li {
  display: none;
  /* Hide the slides before the JS is loaded. Avoids image jumping */
  margin: 0;
  position: relative;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden; }

.flexslider .slides img {
  max-width: 100%;
  margin: 0 auto;
  display: block; }

.slides {
  *zoom: 1; }
  .slides:after {
    content: "";
    display: table;
    clear: both; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

/*================ No JS Fallback ================*/
.no-js .slides > li:first-child {
  display: block; }

.flexslider {
  position: relative;
  zoom: 1; }

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.loading .flex-viewport {
  max-height: 300px; }

.flexslider .slides {
  zoom: 1; }

.carousel li {
  margin-right: 5px; }

/*================ Direction Nav ================*/
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none; }

.flex-direction-nav {
  *height: 0; }

.flex-direction-nav a {
  display: block;
  width: 45px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0.4s;
  -moz-transition: all 0.3s ease 0.4s;
  -ms-transition: all 0.3s ease 0.4s;
  -o-transition: all 0.3s ease 0.4s;
  transition: all 0.3s ease 0.4s; }

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default; }

.flex-direction-nav a {
  text-indent: -9999px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 20px auto;
  /*================ Hide SVG arrows in oldIE ================*/ }
  .lte-ie9 .flex-direction-nav a {
    display: none; }
  .flex-direction-nav a.flex-prev {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIuM3B4IiBoZWlnaHQ9IjQwcHgiIHZpZXdCb3g9IjAgMCAyMi4zIDQwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMi4zIDQwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNEM0QzRDMiIGQ9Ik0xOC43LDBMMCwxOS43TDE4LjcsNDBjMCwwLDUuMi0xLDMuMS0zLjFTNS43LDE5LjcsNS43LDE5LjdzMTQtMTQuNSwxNi4xLTE2LjZTMTguNywwLDE4LjcsMHoiLz4NCjwvc3ZnPg0K");
    background-position: center center; }
  .flex-direction-nav a.flex-next {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIuM3B4IiBoZWlnaHQ9IjQwcHgiIHZpZXdCb3g9IjAgMCAyMi4zIDQwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMi4zIDQwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNEM0QzRDMiIGQ9Ik0wLjUsMy4xYzIuMSwyLjEsMTYuMSwxNi42LDE2LjEsMTYuNlMyLjYsMzQuOCwwLjUsMzYuOVMzLjYsNDAsMy42LDQwbDE4LjctMjAuM0wzLjYsMEMzLjYsMC0xLjYsMSwwLjUsMy4xDQoJeiIvPg0KPC9zdmc+DQo=");
    background-position: center center; }

/*================ Control Nav ================*/
.flex-control-nav {
  position: absolute;
  bottom: 15px;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 2; }
  .flex-control-nav li {
    margin: 0 4px;
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: middle; }

.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: block;
  background-color: #ededed;
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 20px;
  border: 2px solid #fff; }
  .flex-control-paging li a:hover {
    background-color: #333; }
  .flex-control-paging li a.flex-active {
    background-color: #fff;
    border-color: #f45b4f;
    cursor: default; }

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden; }

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0; }

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer; }

.flex-control-thumbs img:hover {
  opacity: 1; }

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default; }

.flexslider:hover .flex-next,
.flexslider:hover .flex-prev {
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.flex-direction-nav .flex-prev {
  left: 20px; }

.flex-direction-nav .flex-next {
  right: 20px; }

.flexslider:hover .flex-prev {
  left: 0; }

.flexslider:hover .flex-next {
  right: 0; }

/*================ Custom Flexslider Styles ================*/
.flexslider .slides {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.slide-link {
  display: block; }
  .slide-link img {
    display: block; }

/*================ Social share buttons ================*/
.social-sharing {
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*================ Large Buttons ================*/ }
  .social-sharing * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .social-sharing a {
    display: inline-block;
    color: #fff;
    border-radius: 2px;
    margin: 5px 10px 5px 0;
    height: 22px;
    line-height: 22px;
    text-decoration: none;
    font-weight: normal; }
    .social-sharing a:hover {
      color: #fff; }
  .social-sharing span {
    display: inline-block;
    vertical-align: top;
    height: 22px;
    line-height: 22px;
    font-size: 12px; }
  .social-sharing .icon {
    padding: 0 5px 0 10px; }
    .social-sharing .icon:before {
      line-height: 22px; }
  .social-sharing.is-large a {
    height: 44px;
    line-height: 44px; }
    .social-sharing.is-large a span {
      height: 44px;
      line-height: 44px;
      font-size: 18px; }
    .social-sharing.is-large a .icon {
      padding: 0 10px 0 18px; }
      .social-sharing.is-large a .icon:before {
        line-height: 44px; }

.share-title {
  font-weight: 900;
  font-size: 12px;
  padding-right: 10px; }
  .is-large .share-title {
    padding-right: 16px; }

.share-facebook {
  background-color: #3b5998; }
  .share-facebook:hover {
    background-color: #2d4373; }

.share-twitter {
  background-color: #00aced; }
  .share-twitter:hover {
    background-color: #0087ba; }

.share-pinterest {
  background-color: #cb2027; }
  .share-pinterest:hover {
    background-color: #9f191f; }

.share-fancy {
  background-color: #4999dc; }
  .share-fancy:hover {
    background-color: #2780cb; }

.share-google {
  background-color: #dd4b39; }
  .share-google:hover {
    background-color: #c23321; }

.share-count {
  position: relative;
  background-color: white;
  padding: 0 8px;
  margin-right: -2px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  border-radius: 0 2px 2px 0;
  border: 1px solid #ececec;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in; }
  .share-count.is-loaded {
    opacity: 1; }
  .share-count:before, .share-count:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid; }
  .share-count:before {
    left: -6px;
    border-right-color: #ececec; }
  .share-count:after {
    left: -5px;
    border-right-color: white; }
  a:hover .share-count {
    background-color: whitesmoke; }
    a:hover .share-count:after {
      border-right-color: whitesmoke; }
  .is-large .share-count {
    font-size: 18px;
    padding: 0 14px; }

/*================ Clean Buttons ================*/
.social-sharing.is-clean a {
  background-color: #fff;
  border: 1px solid #ececec;
  color: #333;
  height: 30px;
  line-height: 30px; }
  .social-sharing.is-clean a span {
    height: 30px;
    line-height: 30px;
    font-size: 13px; }
  .social-sharing.is-clean a:hover {
    background-color: #ececec; }
  .social-sharing.is-clean a:hover .share-count {
    background-color: white; }
    .social-sharing.is-clean a:hover .share-count:after {
      border-right-color: white; }
  .social-sharing.is-clean a .share-title {
    font-weight: normal; }
.social-sharing.is-clean .share-count {
  top: -1px; }
.social-sharing.is-clean .icon-facebook {
  color: #3b5998; }
.social-sharing.is-clean .icon-twitter {
  color: #00aced; }
.social-sharing.is-clean .icon-pinterest {
  color: #cb2027; }
.social-sharing.is-clean .icon-fancy {
  color: #4999dc; }
.social-sharing.is-clean .icon-google {
  color: #dd4b39; }

/*================ View-specific styles ================*/
/*============= Templates | Password page =============*/
.template-password {
  height: 100vh;
  text-align: center; }

.password-page__wrapper {
  display: table;
  height: 100%;
  width: 100%;
  background-image: url(//cdn.shopify.com/s/files/1/1025/1407/t/17/assets/password-page-background.jpg?v=129873446965807520001445359554);
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff; }
  .password-page__wrapper a {
    color: inherit; }
  .password-page__wrapper hr {
    padding: 15px 0;
    margin: 0 auto;
    max-width: 60px;
    border-color: inherit; }
  .password-page__wrapper .social-sharing a {
    color: #fff; }
  .password-page__wrapper .social-sharing.is-clean a {
    color: #333;
    background: #fff; }
    .password-page__wrapper .social-sharing.is-clean a:hover {
      background: #ececec; }

.password-page__header {
  display: table-row;
  height: 1px; }

.password-page__header__inner {
  display: table-cell;
  padding: 15px 30px; }

.password-page__logo {
  margin-top: 90px;
  color: inherit; }
  .password-page__logo .logo {
    width: 1100px;
    max-width: 100%; }

.password-page__main {
  display: table-row;
  width: 100%;
  height: 100%;
  margin: 0 auto; }

.password-page__main__inner {
  display: table-cell;
  vertical-align: middle;
  padding: 15px 30px; }

.password-page__hero {
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 2.47059em;
  line-height: 1.25;
  text-transform: none;
  letter-spacing: 0;
  text-rendering: optimizeLegibility; }
  @media screen and (min-width: 481px) {
    .password-page__hero {
      font-size: 3.52941em; } }
  @media screen and (min-width: 769px) {
    .password-page__hero {
      font-size: 3.76471em; } }

.password-page__message {
  font-style: italic;
  font-size: 120%; }
  .password-page__message img {
    max-width: 100%; }

.password-page__message,
.password-page__login-form,
.password-page__signup-form {
  max-width: 500px;
  margin: 0 auto; }

.password-page__message,
.password-page__login-form {
  text-align: center;
  padding: 30px; }

@media screen and (min-width: 480px) {
  .password-page__login-form,
  .password-page__signup-form {
    padding: 0 30px; } }
.password-page__login-form .input-group,
.password-page__signup-form .input-group {
  width: 100%; }
.password-page__login-form .errors ul,
.password-page__signup-form .errors ul {
  list-style-type: none;
  margin-left: 0; }

.lt-ie9 .template-password .newsletter__submit-text--small,
.lt-ie9 .template-password .password-page__login-form__submit-text--small {
  display: none !important; }

input[type="submit"].password-page__login-form__submit,
input[type="submit"].password-page__signup-form__submit {
  font-size: 0.9em; }

.password-page__social-sharing {
  margin-top: 30px; }

.password-login,
.admin-login {
  margin-top: 15px; }
  .password-login a:hover,
  .admin-login a:hover {
    color: inherit; }

.password-login {
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", sans-serif;
  font-size: 0.82353em;
  line-height: 14px; }

.lock-icon-svg {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: baseline;
  /* Hiding the SVG logo in IE8 */ }
  .lock-icon-svg path {
    fill: currentColor; }
  .lt-ie9 .lock-icon-svg {
    display: none; }

.admin-login {
  font-size: 95%; }

.password-page__footer {
  display: table-row;
  height: 1px;
  color: inherit; }

.password-page__footer_inner {
  display: table-cell;
  vertical-align: bottom;
  padding: 30px;
  line-height: 25.5px;
  font-size: 95%; }

.shopify-link {
  color: inherit; }
  .shopify-link:hover {
    color: inherit; }

.shopify-logo-svg {
  width: 87.42857px;
  height: 25.5px;
  display: inline-block;
  line-height: 0;
  vertical-align: top;
  /* Hiding the SVG logo in IE8, we show the word 'Shopify' instead */ }
  .shopify-logo-svg path {
    fill: currentColor; }
  .lt-ie9 .shopify-logo-svg {
    display: none; }

/* =========
   Hiding the word 'Shopify' but not from screen readers.
   IE8 does not support SVG, so in it we hide the logo and show the word.
   To target all browsers except IE8, we use the class 'modern',
   which needs to be added to the html element.
   ========= */
.modern .shopify-name {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px; }
